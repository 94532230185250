import React, { createContext } from 'react';

export interface CompanyInfoProps {
    address: string;
    companyName: string;
    county: string;
    email: string;
    linkedin: string;
    phoneNumber: string;
    postcode: string;
    town: string;
    footerText?: string;
    twitter?: string;
    footerImage?: any;
}

export interface HeaderPopupProps {
    open: boolean;
    onOpen: any;
    onClose: any;
    openMobile: boolean;
    onOpenMobile: any;
    onCloseMobile: any;
}

export interface NavProps {
    resetDropdownState: any;
    onMouseEnter: any;
    onMouseLeave: any;
}
export const CompanyContext = createContext<Partial<CompanyInfoProps>>({});

export const HeaderPopupContext = createContext<Partial<HeaderPopupProps>>({});

export const NavContext = createContext<Partial<NavProps>>({});
