import { Box, Heading } from 'grommet';
import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
// import { Link } from 'gatsby';
import Entry from '../entry';
import Link from '../link';
import { DropdownSection } from './Components';
import { NavContext } from '../../context';

const DropdownEl = styled(Box)`
    width: 100%;
    z-index: 24;
`;
const SubDropdownEl = styled(Box)`
    /* position: absolute;
    top: 0;
    left: 240px; */
`;

const DropdownListItem = styled.li`
    ${({ theme, active }) => css`
        padding: 0;
        margin: 0;

        a {
            display: block;
            color: ${theme.global.colors.text.light};
            padding: ${theme.global.edgeSize.small};

            ${active &&
                css`
                    background-color: #fff;
                    text-decoration: none;
                `}

            &:hover {
                background-color: #fff;
                text-decoration: none;
            }
        }
    `};
`;

const DropdownList = styled.ul`
    ${({ theme }) => css`
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 240px;
    `};
`;

const SubSubMenu = ({ items, index }) => {
    const { resetDropdownState } = useContext(NavContext);
    return (
        <>
            <SubDropdownEl background={{ color: '#fff' }} pad="medium" flex={{ grow: 1 }}>
                <DropdownList>
                    {items.map((sub, i) => (
                        <DropdownListItem key={`subsub${i}`}>
                            <Link to={sub.url} onClick={resetDropdownState}>
                                {sub.label}
                            </Link>
                        </DropdownListItem>
                    ))}
                </DropdownList>
            </SubDropdownEl>
        </>
    );
};
const Dropdown = ({ items, featured }) => {
    const [active, setActive] = useState(0);

    const { resetDropdownState } = useContext(NavContext);

    // build array of su sub menus

    const subSubs = items.map((item, index) => {
        if (item.children && item.children.length >= 1) {
            return <SubSubMenu items={item.children} index={index} />;
        }
        return null;
    });

    const onMouseEnterActive = index => {
        if (active !== index) {
            setActive(index);
        }
    };

    return (
        <DropdownEl pad="medium" data-first-dropdown-section>
            <DropdownSection pad="medium" cssGap gap="medium" direction="row">
                <Box flex={{ grow: 1 }} direction="row">
                    <DropdownList>
                        {items.map((item, index) => (
                            <DropdownListItem
                                key={`drop-${index}`}
                                active={index === active && item.children && item.children.length >= 1}
                            >
                                <Link
                                    to={item.url}
                                    onMouseEnter={() => onMouseEnterActive(index)}
                                    onClick={resetDropdownState}
                                >
                                    {item.label}
                                </Link>
                            </DropdownListItem>
                        ))}
                    </DropdownList>
                    {subSubs[active]}
                </Box>

                {featured && featured.featuredContent && (
                    <Box width="340px" background={{ color: '#fff' }} pad="medium">
                        <Heading level={3} margin="none" textAlign="center" color="accent-2">
                            {featured.featuredTitle}
                        </Heading>
                        {featured && featured.featuredContent && (
                            <Box>
                                <Entry
                                    featuredImage={featured.featuredContent?.featuredImage}
                                    title={featured.featuredContent.title}
                                    uri={featured.featuredContent.uri}
                                    size="230px 230px"
                                    clickEvt={resetDropdownState}
                                    loading="eager"
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </DropdownSection>
        </DropdownEl>
    );
};

export default React.memo(Dropdown);
