import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
// import maskimage from '../images/mask.svg';
// import maskimageFlipper from '../images/mask-flipped.svg';

export const StyledMask = styled(GatsbyImage)`
    border-radius: 4px;
    overflow: hidden;

    img {
        border-radius: 4px;
    }
`;
export const StyledMaskImg = styled.div`
    border-radius: 4px;
    overflow: hidden;

    img {
        border-radius: 4px;
    }
`;
