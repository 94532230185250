import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'grommet';

const TEXT_ALIGN_MAP = {
    center: 'center',
    end: 'right',
    start: 'left',
};

const textAlignStyle = css`
    text-align: ${props => TEXT_ALIGN_MAP[props.textAlign]};
`;

const HtmlContentElm = styled(Text)`
    ${props => props.textAlign && textAlignStyle}
    ${props =>
        props.marginBottom &&
        css`
            margin-bottom: 16px;
        `}
    ${({ theme }) => css`
        a {
            color: ${theme.global.colors.brand.light};
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: 300;
            color: #616162;
        }
        .entry-content img {
            margin: 0 0 ${theme.global.edgeSize.medium} 0;
        }
        .alignleft,
        img.alignleft {
            margin-right: ${theme.global.edgeSize.medium};
            float: left;
        }
        .alignright,
        img.alignright {
            margin-left: ${theme.global.edgeSize.medium};
            float: right;
        }
        .aligncenter,
        img.aligncenter {
            margin-right: auto;
            margin-left: auto;
            display: block;
            clear: both;
        }
        .alignnone,
        img.alignnone {
        }
        .wp-caption {
            margin-bottom: ${theme.global.edgeSize.medium};
            text-align: center;
            padding-top: 5px;
        }
        .wp-caption img {
            border: 0 none;
            padding: 0;
            margin: 0;
        }
        .wp-caption p.wp-caption-text {
            line-height: 1.5;
            font-size: 10px;
            margin: 0;
        }
        .wp-smiley {
            margin: 0 !important;
            max-height: 1em;
        }

        blockquote {
            font-size: 120%;
            color: ${theme.global.colors.brand.light};
            margin: 0;
            padding: ${theme.global.edgeSize.small} ${theme.global.edgeSize.small} ${theme.global.edgeSize.small}
                ${theme.global.edgeSize.medium};
            border-left: 4px solid ${theme.global.colors.brand.light};
            font-style: italic;
        }
        blockquote.left {
            margin-right: 20px;
            text-align: right;
            margin-left: 0;
            width: 33%;
            float: left;
        }
        blockquote.right {
            margin-left: 20px;
            text-align: left;
            margin-right: 0;
            width: 33%;
            float: right;
        }
        .gallery dl {
        }
        .gallery dt {
        }
        .gallery dd {
        }
        .gallery dl a {
        }
        .gallery dl img {
        }
        .gallery-caption {
        }

        .size-full {
        }
        .size-large {
        }
        .size-medium {
        }
        .size-thumbnail {
        }

        table {
            border: none;
            border-spacing: ${theme.global.edgeSize.small};

            td,
            th {
                padding: ${theme.global.edgeSize.small};
                border: 1px solid ${theme.global.colors['light-3']};
            }

            tr {
                &:nth-child(odd) td {
                    background-color: ${theme.global.colors['light-3']};
                }
            }
        }
    `}
`;

const HtmlContent = props => <HtmlContentElm as="div" {...props} />;
export default HtmlContent;
