import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { NavContext } from '../../context';

const NavbarItemTitle = styled(Link)`
    ${({ theme }) => css`
        background: transparent;
        border: 0;
        font-weight: 500;
        font-family: inherit;
        font-size: 16px;
        padding: ${theme.global.edgeSize.small};
        color: #fff;
        display: flex;
        justify-content: center;
        transition: opacity 250ms;
        cursor: pointer;
        text-decoration: none;
        /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
        position: relative;
        z-index: 22;
        &.is-active {
            color: #fff;
            font-weight: 700;
        }
        &:hover,
        &:focus {
            outline: none;
            color: #fff;
        }
    `}
`;

const NavbarItemEl = styled.li`
    /* position: relative; */
`;

const DropdownSlot = styled.div`
    position: absolute;
    left: 0;
    right: 20px;
    z-index: 30;

    @media (min-width: 1400px) {
        left: 235px;
    }
    //perspective: 1500px;
`;

interface Props {
    title: string;
    index: number;
    children?: any;
    to: string;
}

const NavbarItem: FC<Props> = props => {
    const { title, to, children, index } = props;

    const { onMouseEnter } = useContext(NavContext);

    return (
        <NavbarItemEl>
            <NavbarItemTitle
                to={to}
                activeClassName="is-active"
                partiallyActive
                onMouseEnter={() => onMouseEnter(props.index)}
                onFocus={() => onMouseEnter(index)}
            >
                {title}
            </NavbarItemTitle>
            <DropdownSlot>{children}</DropdownSlot>
        </NavbarItemEl>
    );
};
export default React.memo(NavbarItem);
