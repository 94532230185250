import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';

const httpLink = createHttpLink({
    uri: process.env.GATSBY_WORDPRESS_API_URL,
    // credentials: 'include',
});

// const authLink = setContext(({ variables }, { headers }) =>
//     // return the headers to the context so httpLink can read them
//     ({
//         headers: {
//             ...headers,
//             'X-WP-Nonce': variables && variables.nonce ? variables.nonce : '',
//         },
//     })
// );

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    fetch,
});

export default client;
