import React, { FC } from 'react';
import { Button as GButton } from 'grommet';
import { replaceSiteUrl, isExternal } from '../utils';
import { AdapterLink } from './adapterLink';

interface ButtonProps {
    to: string;
    label: any;
    reverse?: boolean;
    icon?: any;
}
const Button: FC<ButtonProps> = ({ to, label, reverse = false, icon, ...rest }) => {
    const toLink = replaceSiteUrl(to);

    return (
        <GButton
            href={toLink}
            as={isExternal(toLink) ? 'button' : AdapterLink}
            {...rest}
            label={label}
            reverse={reverse}
        />
    );
};

export default Button;
