import React, { useContext } from 'react';
import { Layer, Box, Heading, Button } from 'grommet';
import { Close } from 'grommet-icons';
import { HeaderPopupContext } from '../context';
import { ContactForm } from './contactForm';

export const HeaderFrom = () => {
    const { open, onClose } = useContext(HeaderPopupContext);
    return (
        <>
            {open && (
                <Layer position="center" modal onClickOutside={onClose} onEsc={onClose}>
                    <Box as="form" fill="vertical" overflow="auto" width="medium" pad="medium" onSubmit={onClose}>
                        <Box flex={false} direction="row" justify="between">
                            <Heading level={3} margin="none" color="accent-2">
                                Contact Us
                            </Heading>
                            <Button icon={<Close />} onClick={onClose} />
                        </Box>
                        <Box flex="grow" overflow="auto" pad={{ vertical: 'medium' }}>
                            <ContactForm />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
};
