import React, { useState, useRef } from 'react';
import Navbar from './Navbar';
import NavbarItem from './Navbar/NavbarItem';
import DropdownContainer from './DropdownContainer';
import TheDropDown from './DropdownContents/Dropdown';
import { replaceSiteUrl } from '../utils';
import { NavContext } from '../context';

const AnimatedNavbar = ({ duration, items }) => {
    const [activeIndices, setActiveIndices] = useState<any>([]);

    const navbarConfig = items.map(item => {
        const theItem: any = { title: item.label, to: replaceSiteUrl(item.url) };
        // console.log(item.menuItemExtras);

        if (item.children.length >= 1) {
            theItem.dropdown = () => <TheDropDown items={item.children} featured={item.menuItemExtras} />;
        }

        return theItem;
    });

    const animatingOutTimeout = useRef<any>();

    const resetDropdownState = i => {
        const ai = typeof i === 'number' ? [i] : [];
        setActiveIndices(ai);

        animatingOutTimeout.current = undefined;
    };

    const onMouseEnter = i => {
        if (animatingOutTimeout) {
            clearTimeout(animatingOutTimeout.current);
            resetDropdownState(i);
            return;
        }
        if (activeIndices[activeIndices.length - 1] === i) {
            return;
        }
        const activeIndicesNew = activeIndices.concat(i);
        setActiveIndices(activeIndicesNew);
    };

    const onMouseLeave = () => {
        animatingOutTimeout.current = setTimeout(resetDropdownState, duration);
    };

    let CurrentDropdown;

    let direction;

    const currentIndex = activeIndices[activeIndices.length - 1];
    const prevIndex = activeIndices.length > 1 && activeIndices[activeIndices.length - 2];

    if (typeof currentIndex === 'number') {
        CurrentDropdown = navbarConfig[currentIndex].dropdown || null;
    }
    if (typeof prevIndex === 'number') {
        direction = currentIndex > prevIndex ? 'right' : 'left';
    }

    return (
        <NavContext.Provider
            value={{
                resetDropdownState,
                onMouseEnter,
                onMouseLeave,
            }}
        >
            <Navbar onMouseLeave={onMouseLeave}>
                {navbarConfig.map((n, index) => (
                    <NavbarItem key={n.title} title={n.title} index={index} to={n.to}>
                        {n.dropdown && currentIndex === index && (
                            <DropdownContainer direction={direction} duration={duration}>
                                {CurrentDropdown && <CurrentDropdown />}
                            </DropdownContainer>
                        )}
                    </NavbarItem>
                ))}
            </Navbar>
        </NavContext.Provider>
    );
};

export default AnimatedNavbar;
