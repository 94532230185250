import React, { FC } from 'react';
import styled from 'styled-components';

const NavbarEl = styled.nav`
    margin: auto;
    z-index: 11;
`;

const NavbarList = styled.ul`
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Navbar: FC<any> = ({ children, onMouseLeave }) => (
    <NavbarEl onMouseLeave={onMouseLeave}>
        <NavbarList>{children}</NavbarList>
    </NavbarEl>
);

export default Navbar;
