/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Footer as GFooter, Text, Box } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import Link from './link';
import { MaxWidth } from './maxWidth';
import { createMarkup } from '../utils';
import { CompanyInfoProps } from '../context';

const StyledAddress = styled(Text)`
    text-align: center;
    @media (min-width: 768px) {
        margin-left: auto;
        text-align: right;
    }
`;
const StyledFooterSocial = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: #fff;
    padding: 10px;
    justify-content: center;

    @media (min-width: 768px) {
        justify-content: flex-end;
    }
    a {
        color: #fff;
    }

    svg {
        width: 36px;
        height: auto;
    }
`;
const StyledFooterImage = styled(Box)`
    text-align: center;
    justify-content: center;
    width: 100%;
    align-self: center;
    max-width: 300px;

    @media (min-width: 768px) {
        text-align: left;
        justify-content: flex-start;
        align-self: flex-start;
    }
`;

const StyledNav = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
        flex-direction: row;
    }
`;

const StyledUpperNav = styled(StyledNav)`
    max-width: 380px;
    align-self: center;
    @media (min-width: 768px) {
        align-self: flex-start;
    }
    > * {
    }
`;

const StyledNavItem = styled.li`
    ${({ theme }) => css`
        text-align: inherit;
        padding: ${theme.global.edgeSize.xsmall} 0;
        @media (min-width: 768px) {
            padding: ${theme.global.edgeSize.small} ${theme.global.edgeSize.small};
        }

        a {
            color: #fff;
        }
    `}
`;

interface Props {
    companyInfo: CompanyInfoProps;
}

const Footer: FC<Props> = ({ companyInfo }) => {
    const { upperMenu, lowerMenu } = useStaticQuery(graphql`
        query FooterNavQuery {
            upperMenu: allWpMenuItem(filter: { locations: { eq: FOOTER_MENU } }) {
                nodes {
                    url
                    label
                    target
                    id
                }
            }

            lowerMenu: allWpMenuItem(filter: { locations: { eq: SECONDARY_FOOTER_MENU } }) {
                nodes {
                    url
                    label
                    target
                    id
                }
            }
        }
    `);

    return (
        <GFooter background={{ color: 'dark-1', dark: true }} justify="center" pad="small" direction="column">
            <MaxWidth direction="row-responsive" gap="large" pad={{ top: 'medium' }}>
                <Box flex={{ shrink: 0, grow: 1 }}>
                    {upperMenu?.nodes && (
                        <StyledUpperNav>
                            {upperMenu.nodes.map(({ label, url, id }) => (
                                <StyledNavItem key={id}>
                                    <Link to={url}>{label}</Link>
                                </StyledNavItem>
                            ))}
                        </StyledUpperNav>
                    )}

                    {companyInfo?.footerImage && (
                        <StyledFooterImage width={{ max: '300px' }} margin={{ top: 'small' }}>
                            <GatsbyImage
                                image={companyInfo.footerImage.localFile.childImageSharp.gatsbyImageData}
                                alt={companyInfo.footerImage.altText}
                            />
                            <br />
                            <Box background="light-1" pad="small">
                                <a
                                    href="https://www.checkatrade.com/trades/ecoroofingse"
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    <img
                                        src="http://www.checkatrade.com/Images/ProudMembersOfCAT-230x60.jpg"
                                        alt="Checkatrade information for Eco Roofing SE"
                                    />
                                </a>
                            </Box>
                        </StyledFooterImage>
                    )}
                </Box>

                {companyInfo && (
                    <StyledAddress as="address">
                        <strong>{companyInfo.companyName}</strong>
                        <br />
                        <span
                            dangerouslySetInnerHTML={createMarkup(
                                companyInfo.address.replace(/(\r\n|\n|\r)/gm, '<br />')
                            )}
                        />
                        <br />
                        {companyInfo.town}
                        <br />
                        {companyInfo.county}
                        <br />
                        {companyInfo.postcode} UK
                        <br />
                        <StyledFooterSocial>
                            <a
                                href="https://instagram.com/ecoroofingse?igshid=1tkc96gm5n5dc"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaInstagramSquare />
                            </a>
                            <a href="https://facebook.com/ecoroofingse/" target="_blank" rel="noreferrer">
                                <FaFacebookSquare />
                            </a>
                        </StyledFooterSocial>
                    </StyledAddress>
                )}
            </MaxWidth>
            {lowerMenu && lowerMenu.nodes && (
                <Box>
                    <StyledNav>
                        {lowerMenu.nodes.map(({ label, url, id }) => (
                            <StyledNavItem key={id}>
                                <Link to={url}>{label}</Link>
                            </StyledNavItem>
                        ))}
                    </StyledNav>
                </Box>
            )}
            {companyInfo && companyInfo.footerText && (
                <Box>
                    <Text size="small">{companyInfo.footerText}</Text>
                </Box>
            )}
        </GFooter>
    );
};

export default Footer;
