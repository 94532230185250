import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyledMask } from './imageMasks';
import maskCardimage from '../images/card-mask.svg';
import maskCardbg from '../images/card-bg.svg';

const Soon = ({ size = '300px 300px' }) => {
    const data = useStaticQuery(graphql`
        {
            placeholderImage: file(relativePath: { eq: "coming-soon.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 300, height: 280, quality: 100, layout: FIXED)
                }
            }
        }
    `);

    return (
        <StyledMask
            size={size}
            imgSize={size}
            mask={maskCardimage}
            image={data.placeholderImage.childImageSharp.gatsbyImageData}
            alt=""
        />
    );
};

export default Soon;
