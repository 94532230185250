import { Box, Text } from 'grommet';
import React, { FC, useContext } from 'react';
import { CompanyContext } from '../context';
import Button from './button';

interface HeaderCtaProps {}

const HeaderCta: FC<HeaderCtaProps> = () => {
    const { phoneNumber } = useContext(CompanyContext);
    // const { onOpen } = useContext(HeaderPopupContext);

    return (
        <Box direction="row" gap="medium" justify="center" align="center" style={{ marginLeft: 'auto' }}>
            <Text>{phoneNumber}</Text>
            <Button
                primary
                label={
                    <Text color="white" textAlign="center">
                        Book Appointment
                    </Text>
                }
                alignSelf="end"
                to="/contact-us"
                color="accent-1"
            />
        </Box>
    );
};

export default HeaderCta;
