import React, { FC, useState, useContext } from 'react';
import { FormClose, Menu, Phone } from 'grommet-icons';
import { Box, Button as Gbutton, Layer, Anchor, Text } from 'grommet';
import styled, { css } from 'styled-components';
import { HeaderPopupContext, CompanyContext } from '../context';
import Link from './link';
import Button from './button';

const StyledLayer = styled(Layer)`
    ${({ active, theme }) => css`
        display: ${active ? 'block' : 'none'};
    `}
`;

const StyledMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
const StyledSubMenuList = styled.ul`
    ${({ active, theme }) => css`
        display: ${active ? 'block' : 'none'};
        background-color: ${theme.global.colors['light-3']};
        list-style: none;
        padding: ${theme.global.edgeSize.medium} 0;
        margin: 0;
    `}
`;

const StyledMenuItem = styled.li`
    ${({ theme, sub }) => css`
        a,
        .link {
            text-decoration: none;
            display: block;
            padding: ${theme.global.edgeSize.small} ${theme.global.edgeSize.medium};
            color: ${theme.global.colors.text.light};

            &:hover {
                background-color: ${theme.global.colors['light-2']};
            }
        }
    `}
`;

const MenuItem = ({ url, label, id, children }) => {
    const { onCloseMobile } = useContext(HeaderPopupContext);

    const [show, setShow] = useState(false);
    const handleToggle = e => {
        e.preventDefault();
        setShow(!show);
    };

    return (
        <StyledMenuItem>
            {children && children.length >= 1 ? (
                <>
                    <Anchor href={url} className="link" onClick={e => handleToggle(e)}>
                        {label}
                    </Anchor>

                    <StyledSubMenuList active={show}>
                        {children.map(item => (
                            <StyledMenuItem key={item.id}>
                                <Link to={item.url} className="link sub-link" onClick={onCloseMobile}>
                                    {item.label}
                                </Link>
                            </StyledMenuItem>
                        ))}
                    </StyledSubMenuList>
                </>
            ) : (
                <Link to={url} className="link" onClick={onCloseMobile}>
                    {label}
                </Link>
            )}
        </StyledMenuItem>
    );
};

interface props {
    items: any[];
}
const MobileNavbar: FC<props> = ({ items }) => {
    const { onCloseMobile, onOpenMobile, openMobile, onOpen } = useContext(HeaderPopupContext);
    const { phoneNumber } = useContext(CompanyContext);

    const handleToggleSidebar = () => {
        if (openMobile) {
            onCloseMobile();
        } else {
            onOpenMobile();
        }
    };

    const handleCallBack = () => {
        onCloseMobile();
        onOpen();
    };
    return (
        <>
            <Box direction="row" gap="small" margin={{ left: 'auto' }} background={{ dark: false }}>
                <Gbutton
                    icon={<Phone />}
                    as="a"
                    href={`tel:${phoneNumber?.replace(/ /g, '')}`}
                    primary
                    color="light-2"
                />
                <Gbutton icon={<Menu />} onClick={handleToggleSidebar} primary color="light-2" />

                {openMobile && (
                    <StyledLayer active={openMobile} modal>
                        <Box align="end">
                            <Gbutton icon={<FormClose size="large" />} onClick={handleToggleSidebar} />
                        </Box>
                        <Box>
                            <StyledMenuList>
                                {items.map(item => (
                                    <MenuItem {...item} key={item.id} />
                                ))}
                            </StyledMenuList>
                            <Box pad="medium" align="start" justify="center">
                                <Button
                                    primary
                                    label={<Text color="white">Request Callback</Text>}
                                    to="/contact-us"
                                    onClick={onCloseMobile}
                                />
                            </Box>
                        </Box>
                    </StyledLayer>
                )}
            </Box>
        </>
    );
};

export default MobileNavbar;
