import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grommet, Box } from 'grommet';
import { Normalize } from 'styled-normalize';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import Header from '../components/header';
import Footer from '../components/footer';
import theme from '../components/theme';
import { CompanyContext, HeaderPopupContext } from '../context';
import Banner from '../components/banner';

export const GlobalStyle = createGlobalStyle`

 * {
    box-sizing: border-box;
 }

address {
    font-style: normal;
}

.page-heading.light-1 + .component-renderer > .light-1:first-child {

    padding-top: 0;
    .block{
    padding-top: 0;
}
}

.show-small {
    @media(min-width: 1201px) {
        display: none;
    }
}
.show-large {
    @media(max-width: 1200px) {
        display: none;
    }
}

 `;

const StyledMain = styled(Box)`
    z-index: 10;
`;

const StyledBg = styled.div``;
const Layout = ({ children }) => {
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const [openMobile, setOpenMobile] = useState(false);
    const onOpenMobile = () => setOpenMobile(true);
    const onCloseMobile = () => setOpenMobile(false);

    const {
        wp: {
            siteSettings: { companyInfo },
            siteBanner,
            seo,
        },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            mediaItemUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1600, layout: FIXED)
                                    fixed(width: 1600) {
                                        src
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
                siteBanner {
                    buttonText
                    show
                    content
                    heading
                    buttonLinkUri
                }
                siteSettings {
                    companyInfo: siteOptions {
                        address
                        companyName
                        county
                        email
                        linkedin
                        phoneNumber
                        postcode
                        town
                        twitter
                        footerText
                        footerImage {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 300, height: 60, quality: 90, layout: FIXED)
                                }
                            }
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `);

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: 'https://www.ecoroofingse.com',
        name: companyInfo.companyName,
        address: {
            '@type': 'PostalAddress',
            addressLocality: 'UK',
            postalCode: companyInfo.postcode,
            streetAddress: `${companyInfo.address}, ${companyInfo.county}, ${companyInfo.town}`,
        },
        email: companyInfo.email,
        telephone: companyInfo.phoneNumber,
    };
    const options = {
        // schemaReplacement: {
        //         from: 'EXAMPLE',
        //         to: 'TO_REPLACE',
        //     }
    };
    return (
        <>
            <SEOContext.Provider value={{ global: seo }}>
                <Helmet encodeSpecialCharacters={false}>
                    <script type="application/ld+json">{JSON.stringify(schema)}</script>
                </Helmet>
                <Normalize />
                <GlobalStyle />
                <CookiesProvider>
                    <CompanyContext.Provider value={{ ...companyInfo }}>
                        <HeaderPopupContext.Provider
                            value={{
                                open,
                                onOpen,
                                onClose,
                                openMobile,
                                onOpenMobile,
                                onCloseMobile,
                            }}
                        >
                            <Grommet theme={theme}>
                                <StyledBg>
                                    {siteBanner.show && <Banner {...siteBanner} />}
                                    <Header companyInfo={companyInfo} />
                                    <StyledMain as="main">{children}</StyledMain>
                                    <Footer companyInfo={companyInfo} />
                                </StyledBg>
                            </Grommet>
                        </HeaderPopupContext.Provider>
                    </CompanyContext.Provider>
                </CookiesProvider>
            </SEOContext.Provider>
        </>
    );
};

export default Layout;
