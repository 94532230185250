import styled, { css } from 'styled-components';
import { Box } from 'grommet';

export const Heading = styled.h3`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: ${props => (props.noMarginBottom ? 0 : '1rem')};
`;

export const HeadingLink = Heading.withComponent('li');

export const LinkList = styled.ul`
    li {
        margin-bottom: 1rem;
    }

    li:last-of-type {
        margin-bottom: 0;
    }

    margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`;

export const DropdownSection = styled(Box)`
    ${({ theme }) => css`
        position: relative;
        z-index: 20;
    `}
`;
